import React, { useContext, useState } from 'react'
import Header from "../../../../Components/Commons/Header"
import MenuConfiguracoes from "../../../../Components/Commons/MenuConfiguracoes"
import MenuLatel from "../../../../Components/Commons/MenuLateral"
import { LegalInsightsCrudContext } from "../../../../contexts/LegalInsights/LegalInsightsCrudContext"
import { menuLateralOpcoesPorModulos } from "../../../../utils/Compartilhados"
import MeuPerfil from "../../../MeuPerfil"
import './index.css'
import { Box  } from '@mui/material'

import ReactLoading from 'react-loading'
import { AuthContext } from '../../../../contexts/LegalInsights/auth'
import { ButtonLabelComponent, ButtonLabelSearchComponent } from '../../../../Components/Commons/Button'
import useForm from '../../../../hooks/useForm'
import { showToast } from '../../../../utils/Compartilhados/CustomToast'
import { DepositoJudicialContext } from '../../../../contexts/ContextDepositoJudicial/DepositoJudicialContext'
// import { DrropdowPadrao } from '../../../../Components/Commons/Select'
// import { listaMes } from '../../../../utils/data'
import GridIdentificacao from './GridIdentificacao'
import { exportExcelCustom } from '../../../../utils/data'

const DepositoJudicialIdentificacao = () => {
    const { showMenuConfiguracoes, showMeuPerfil, loadingDownload } = useContext(LegalInsightsCrudContext)
    const { user, load } = useContext(AuthContext)
    const [form, handleInputChange, clear] = useForm({ processo: '', label: 0, ano: '' })
    const { identificacao, getIdentificacao } = React.useContext(DepositoJudicialContext)
    const [ rowFilter, setRowFilter] = useState([])
    const [ mes , setMes ] = useState(0)

    const handleLimparFiltro = async () => {
        showToast('success','Registro atualizado com sucesso!')
        getIdentificacao([])
        setRowFilter([])
        setMes(0)
        clear()        
    }

    const handleSearchProcessPorMesAno = () => {     
        const _identificacao = identificacao.filter( item => {
            let resultado

            if (form.processo || mes > 0 || form.ano) {
                resultado = true
            
                if (form.processo && form.processo !== '') {
                    resultado = resultado && item.processo.includes(form.processo)
                }
            }
             
            return resultado
        })
        
        if(_identificacao.length === 0 ){
            return showToast('warning','Nenhum registro encontrado!')
        } 

        setRowFilter(_identificacao)

        return showToast('success','Busca realizada com sucesso!')
    }

    const handleOnClick = () => {        
        showToast('warning','Seu download está em andamento. Por favor, aguarde.') 
        const header = {
            empresa: "Empresa",
            descrLinha: "Descrição da linha",
            dtgl: "Data GL",
            periodo: "Período",
            dtCriacao: "Data de criação",
            saldoR: "Saldo R",
            processo: "Processo",
            mesRefPag: "Mês de referência do pagamento",
            feeLegal: "Fee legal",
            linkComprovante: "Link do comprovante",
            controlePagamento: "Controle de pagamento",
            natureza: "Natureza",
            status: "Status",
            autor: "Autor"
        }
        

        
        exportExcelCustom(header, identificacao, 'Identificacao')        
    }

    return(
        <div className="modulo-deposito-judicial-container">
            <Header />   

            <div className="deposito-judicial-container">
                <div className="deposito-judicial-menu-left">
                    <MenuLatel menuLateralPorModulos={menuLateralOpcoesPorModulos('depositoJudicial', user?.perfil)}/>
                </div>

                <div className="deposito-judicial-conteudo">
                        <div className={`main-container-conteudo${showMenuConfiguracoes? '-open' : showMeuPerfil? '-open' : '' } }`} >
                            <div className="conteudo-header">  
                                <div className="header-filter">     
                                    <ButtonLabelSearchComponent label={'Pesquisar por processo'} value={form.processo} name={'processo'} onchange={handleInputChange} onClick={handleSearchProcessPorMesAno}/> 
                                
                               
                                    {/* <DrropdowPadrao label={"Mês"} 
                                        optionLabel={"label"} 
                                        options={listaMes()} 
                                        onChange={(event) => setMes(event.value)} 
                                        value={mes} 
                                    />
                                    <ButtonLabelSearchComponent label={'Pesquisar por ano'} value={form.ano} name={'ano'} onchange={handleInputChange} onClick={handleSearchProcessPorMesAno}/> */}
                                </div>
                               
    
                                <ButtonLabelComponent label={`${loadingDownload? 'Aguarde...' : 'Exportar' }`} onClick={handleOnClick} disabled={loadingDownload? true : false }/>                      
                                <div>                          
                                    <ButtonLabelComponent label={`Limpar filtro`} onClick={handleLimparFiltro} info />                                
                                </div>                         
                            </div>                            
                            
                            <div className="data-grid-meus-processos">

                                {load? 
                                    <div className="loadProcessos">
                                        <ReactLoading type='spin' color='#4e1975' />
                                    </div> 
                                :
                                    <Box sx={{ height: '80vh', width: '100%' }}>
                                       <GridIdentificacao dataFilter={rowFilter} />
                                    </Box>
                                }

                                {loadingDownload && 
                                    <div className="loadProcessos">
                                     <ReactLoading type='spin' color='#4e1975' />
                                    </div>                                     
                                }    
                            </div>
                        </div>
                </div>

                <div className="deposito-judicial-menu-right">
                    {showMenuConfiguracoes && <MenuConfiguracoes /> }
                    {showMeuPerfil && <MeuPerfil /> }              
                </div>       
            </div>  
        </div>
    )
}


export default DepositoJudicialIdentificacao
