import React, { useContext, useState } from 'react'
import Header from "../../../../Components/Commons/Header"
import MenuConfiguracoes from "../../../../Components/Commons/MenuConfiguracoes"
import MenuLatel from "../../../../Components/Commons/MenuLateral"
import { LegalInsightsCrudContext } from "../../../../contexts/LegalInsights/LegalInsightsCrudContext"
import { menuLateralOpcoesPorModulos } from "../../../../utils/Compartilhados"
import MeuPerfil from "../../../MeuPerfil"
import './index.css'
import { Box } from '@mui/material'
import ReactLoading from 'react-loading'
import { AuthContext } from '../../../../contexts/LegalInsights/auth'
import GridSubstituicao from './GridSubstituicao'
import { ButtonLabelComponent, ButtonLabelSearchComponent } from '../../../../Components/Commons/Button'
import useForm from '../../../../hooks/useForm'
import { showToast } from '../../../../utils/Compartilhados/CustomToast'
import { DepositoJudicialContext } from '../../../../contexts/ContextDepositoJudicial/DepositoJudicialContext'
import { DrropdowPadrao } from '../../../../Components/Commons/Select'
import { exportExcelCustom, listaMes } from '../../../../utils/data'

const DepositoJudicialSubstituicao = () => {
    const { showMenuConfiguracoes, showMeuPerfil, loadingDownload } = useContext(LegalInsightsCrudContext)
    const { user, load } = useContext(AuthContext)
    const [form, handleInputChange, clear] = useForm({ processo: '' })
    const { substituicao, getSubstituicao } = React.useContext(DepositoJudicialContext)
    const [ rowFilter, setRowFilter] = useState([])
    const [ mes , setMes ] = useState(0)
    
    const handleLimparFiltro = async () => {
        showToast('success','Registro atualizado com sucesso!')
        getSubstituicao()
        setRowFilter([])
        setMes(0)
        clear()        
    }

    const handleSearchProcessPorMesAno = () => {     
        const _substituicao = substituicao.filter( item => {
            let resultado

            if (form.processo || mes > 0 || form.ano) {
                resultado = true
            
                // Verifica se o filtro de processo foi informado e aplica o filtro
                if (form.processo && form.processo !== '') {
                    resultado = resultado && item.processo.includes(form.processo)
                }
            
                // Verifica se o filtro de mês foi informado e aplica o filtro
                if (mes > 0) {
                    const mesItem = item.mesREF?.split("/")[0] || '' // Pega o valor do mês ou atribui '' se não existir
                    resultado = resultado && mesItem.includes(mes)
                }
                
            
                // Verifica se o filtro de ano foi informado e aplica o filtro
                if (form.ano && form.ano !== '' && form.ano !== 0) {
                    const anoItem = item?.mesREF.split("/")[1] || '' // Pega o valor do ano ou atribui '' se não existir
                    resultado = resultado && anoItem.includes(form.ano)
                }
            }
             
            return resultado
        })
        
        if(_substituicao.length === 0 ){
            return showToast('warning','Nenhum registro encontrado!')
        } 

        setRowFilter(_substituicao)

        return showToast('success','Busca realizada com sucesso!')
    }

    const handleOnClick = () => {        
        showToast('warning','Seu download está em andamento. Por favor, aguarde.') 
        const header = {
            empresa: "Empresa",
            cnpj: "CNPJ",
            autor: "Autor",
            natureza: "Natureza",
            processo: "Processo",
            tribunal: "Tribunal",
            vara: "Vara",
            comarca: "Comarca",
            status: "Status",
            dataDoArquivamento: "Data do arquivamento",
            momentoProcessual: "Momento processual",
            instancia: "Instância",
            tipoConta: "Tipo de conta",
            oQueFazer: "O que fazer",
            dataDoDepostoAberturaContaJUD: "Data do depósito ou abertura de conta JUD",
            dataDoSaldo: "Data do saldo",
            valorRecuperacao: "Valor de recuperação",
            valorParaGestao: "Valor para gestão",
            banco: "Banco",
            idConta: "ID da conta",
            coBase: "CO Base",
            coEstab: "CO Estab",
            coEmpregado: "CO Empregado",
            dtaOrigemDocumento: "Data de origem do documento",
            agencia: "Agência",
            operacao: "Operação",
            conta: "Conta",
            dv: "DV",
            statusFinal: "Status final",
            dataDoPeticao: "Data do petição",
            dataDaPeticao: "Data da petição",
            numeroDaApolice: "Número da apólice",
            vencimentoApolice: "Vencimento da apólice",
            seguradora: "Seguradora",
            valorCreditado: "Valor creditado",
            dataDoCredito: "Data do crédito",
            contaCreditada: "Conta creditada",
            andamentoProcessoAposProtocolo: "Andamento do processo após protocolo",
            fee: "Fee",
            id: "ID",
            proximaFase: "Próxima fase",
            valorConciliado: "Valor conciliado",
            baixaContabil: "Baixa contábil",
            controleEmpresa: "Controle da empresa",
            apontamentoObservacoes: "Apontamento de observações"
        }

        
        exportExcelCustom(header, substituicao, 'Substituicao')        
    }

    return(
        <div className="modulo-deposito-judicial-container">
            <Header />   

            <div className="deposito-judicial-container">
                <div className="deposito-judicial-menu-left">
                    <MenuLatel menuLateralPorModulos={menuLateralOpcoesPorModulos('depositoJudicial', user?.perfil)}/>
                </div>

                <div className="deposito-judicial-conteudo">
                    <div className={`main-container-conteudo${showMenuConfiguracoes? '-open' : showMeuPerfil? '-open' : '' } }`} >
                            <div className="conteudo-header"> 
                            <div className="header-filter">     
                                    <ButtonLabelSearchComponent label={'Pesquisar por processo'} value={form.processo} name={'processo'} onchange={handleInputChange} onClick={handleSearchProcessPorMesAno}/> 
                                
                               
                                    <DrropdowPadrao label={"Mês"} 
                                        optionLabel={"label"} 
                                        options={listaMes()} 
                                        onChange={(event) => setMes(event.value)} 
                                        value={mes} 
                                    />
                                    <ButtonLabelSearchComponent label={'Pesquisar por ano'} value={form.ano} name={'ano'} onchange={handleInputChange} onClick={handleSearchProcessPorMesAno}/>
                                </div>                              
    
                                <ButtonLabelComponent label={`${loadingDownload? 'Aguarde...' : 'Exportar' }`} onClick={handleOnClick} disabled={loadingDownload? true : false }/>                      
                                <div>                          
                                    <ButtonLabelComponent label={`Limpar filtro`} onClick={handleLimparFiltro} info />                                
                                </div>
                            </div>                                                        
                            <div className="data-grid-meus-processos">

                                {load? 
                                    <div className="loadProcessos">
                                        <ReactLoading type='spin' color='#4e1975' />
                                    </div> 
                                :
                                    <Box sx={{ height: '80vh', width: '100%' }}>
                                        <GridSubstituicao  dataFilter={rowFilter} />
                                    </Box>
                                }

                                {loadingDownload && 
                                    <div className="loadProcessos">
                                     <ReactLoading type='spin' color='#4e1975' />
                                    </div>                                     
                                }    
                            </div>
                    </div>
                </div>

                <div className="deposito-judicial-menu-right">
                    {showMenuConfiguracoes && <MenuConfiguracoes /> }
                    {showMeuPerfil && <MeuPerfil /> }              
                </div>       
            </div>  
        </div>
    )
}

export default DepositoJudicialSubstituicao
